@import "attribution";
@import "recirc";

.icon {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
  position: relative;
  top: 4px;
}

.sign {
  display: flex;
  justify-content: space-between;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 20px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1280px) {
    max-width: calc(1120px - 40px);
  }
  a {
    color: #A188EB;
  }
  p,
  .content {
    line-height: 24px;
  }
  .credits {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    &__link {
      margin-top: 10px;
    }
  }
  &-main {
    max-width: 544px;
    color: #fff;
    font-family: "Proxima Nova";
    font-size: 16px;
    margin-right: 60px;
    @media screen and (max-width: 900px) {
      margin: 0 auto;
      width: 100%;
    }
  }
  &-rail {
    width: 300px;
    position: relative;
    @media screen and (max-width: 900px) {
      margin: 0 auto;
      width: 100%;
      max-width: 544px;
    }
    &__sign-module {
      @media screen and (min-width: 901px) {
        position: absolute;
        bottom: 0;
      }
      &.sticky {
        @media screen and (max-height: 950px) {
          display: none;
        }
        .no-ads & {
          display: block;
        }
      }
      &.anchored {
        @media screen and (min-height: 951px) {
          display: none;
        }
      }
    }
    &__title {
      font-size: 24px;
      font-weight: 600;
      font-family: new-spirit;
      color: #FFF;
      text-align: center;
    }
    &__inner {
      top: 50px;
      position: sticky;
      height: calc(100vh - 75px);
      .no-ads & {
        height: 300px;
      }
    }
  }
  &-header {
    &__img {
      background-image: var(--background-image);
      background-repeat: no-repeat;
      width: 600px;
      height: 200px;
      transform: translate(-50px, 13px);
      background-size: contain;
      position: relative;
      opacity: 30%;
    }
    &__content {
      display: flex;
      position: relative;
      top: -150px;
      margin-bottom: -150px;
      height: 100px;
    }
    &__title {
      font-size: 48px;
      font-weight: 500;
      letter-spacing: -2.4px;
      font-family: new-spirit-condensed;
      text-shadow: 0px 0px 4px #000;
      // margin-bottom: 20px;
      margin-left: 10px;
      opacity: 100%;
      text-transform: capitalize;
    }
    &__symbol {
      position: relative;
      bottom: -45px;
    }
  }
  &-blurb {
    margin-bottom: 60px;
  }
  &-data {
    &__item {
      &:not(&.dates) {
        margin-left: 12px;
      }
      &.dates {
        &:before {
          @extend .icon;
          background-image: url("../public/images/icons/dates.svg");
        }
      }
      &.animal {
        &:before {
          @extend .icon;
          background-image: url("../public/images/icons/avatar.svg");
        }
      }
      &.type {
        &:before {
          @extend .icon;
          background-image: url("../public/images/icons/element.svg");
        }
      }
      &.planet {
        &:before {
          @extend .icon;
          background-image: url("../public/images/icons/planet.svg");
        }
      }
    }
  }
  &-day {
    &__main {
      display: flex;
    }
    &__toggle {
      color: #A188EB;
      font-size: 18px;
      font-weight: 600;
      text-decoration: underline;
      font-family: new-spirit;
      display: flex;
      cursor: pointer;
      &.selected {
        color: #fff;
        text-decoration: none;
        cursor: auto;
      }
      &.yesterday {
        &:before {
          @extend .icon;
          background-image: url("../public/images/icons/yesterday.svg");
        }
        &.selected {
          &:before {
            background-image: url("../public/images/icons/yesterday-sel.svg");
          }
        }
      }
      &.today {
        margin-left: 20px;
        &:before {
          @extend .icon;
          background-image: url("../public/images/icons/today.svg");
        }
        &.selected {
          &:before {
            background-image: url("../public/images/icons/today-sel.svg");
          }
        }
      }
      &.tomorrow {
        margin-left: 20px;
        &:before {
          @extend .icon;
          background-image: url("../public/images/icons/tomorrow.svg");
        }
        &.selected {
          &:before {
            background-image: url("../public/images/icons/tomorrow-sel.svg");
          }
        }
      }
      &-container {
        display: flex;
      }
    }
    &__text {
      margin-top: 10px;
    }
  }
  &-week {
    &__title {
      margin-top: 60px;
      font-size: 18px;
      font-weight: 600;
      color: #FFF;
      font-family: new-spirit;
      &:before {
        @extend .icon;
        background-image: url("../public/images/icons/love.svg");
      }
    }
  }
  &-month {
    &__title {
      margin-top: 60px;
      font-size: 18px;
      font-weight: 600;
      color: #FFF;
      font-family: new-spirit;
      &:before {
        @extend .icon;
        background-image: url("../public/images/icons/future.svg");
      }
    }
  }
  .newsletter {
    margin: 60px auto 0;
  }
  .back-to-horoscopes {
    margin: 20px 0 0 5px;
    margin-bottom: 17px;
    font-family: new-spirit;
    font-weight: 700;
    font-size: 14px;
    &:before {
      content: '';
      border: solid #A188EB;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      position: relative;
      top: 4px;
      transform: translateY(-50%) rotate(135deg);
    }
    a {
      color:#A188EB;
    }
  }
}
